import { ApiGatewayPathBuilder, Env, ICandidateEnvironment } from 'shared-lib'

const CANDIDATE_BASE_URL = 'https://network.cnected.com'

const env: Env = 'production'
const pathBuilder = new ApiGatewayPathBuilder(env)

export const environment: ICandidateEnvironment = {
  production: true,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyDCjJ68iZtelmCX9eh6FVyboIQ4O8xwEK8',
    authDomain: 'cnect-prod-100.firebaseapp.com',
    projectId: 'cnect-prod-100',
    storageBucket: 'cnect-prod-100.appspot.com',
    messagingSenderId: '918489190359',
    appId: '1:918489190359:web:8f27de25e14608677a8592',
    databaseURL: 'https://cnect-prod-100.firebaseio.com',
    measurementId: 'G-W86SDZDTBM',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-prod.cloudfunctions.net',
    applicationHistory: pathBuilder.build('applicationHistory'),
    candidate: pathBuilder.build('candidate'),
    content: pathBuilder.build('content'),
    employer: pathBuilder.build('employer'),
    events: pathBuilder.build('events'),
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    messaging: pathBuilder.build('messaging'),
    community: pathBuilder.build('community'),
    virtualDialogue: pathBuilder.build('virtualDialogue'),
    access: pathBuilder.build('access'),
    social: pathBuilder.build('social'),
    notifications: pathBuilder.build('notifications'),
    user: pathBuilder.build('user'),
  },
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false,
  themesEnabled: true,
  v2ReCaptcha: '6LeaW0ocAAAAAAElSsVOmLLXYtSe6qlC8N8ZuXkt',
  tenantId: 'B2C-USER-ypc0y',
  cdnCname: 'content.cnected.com',
  basePlatformHost: 'network.cnected.com',
  algoliaAppId: '8KSBN2S7IA',
  version: '5226718',
  employerEmailActionRoute: 'http://employer.cnected.com/#/auth/actions',
  candidateEmailSuccessRoute: 'https://network.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  emailVerificationEmailTemplateId: 'cwdpiIuZAP8dPQqB5s5K',
  betaMessageId: 'FoyYlvOzSxbxbMvU2ykE',
  candidateBaseUrl: CANDIDATE_BASE_URL,
  appDownloadDynamicLink: 'https://cnect.page.link/uEsh',
  tenant: {
    appConfigBaseURL: 'https://content.cnected.com/assets/bootstrap',
    defaultTenant: 'network.cnected.com',
  },
  smartBannerOptions: {
    appIcon: 'https://play-lh.googleusercontent.com/W170UOarQtwNy4GY1JXosU6SD3LBY5OzTYOWsRUL9W6yXYwPoCeUVGeTtuO850ByjSw=w480-h960-rw',
    appStoreUrl: 'https://apps.apple.com/us/app/cnected/id6467040985',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=com.e11.prod.cnect',
    ttl: `${24 * 3.6e6}`,
  },
}
